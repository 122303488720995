.features-section .icon {
    background-color: #6c63ff; /* Replace with your primary color */
  }
  
  .features-section .card {
    transition: transform 0.2s ease;
  }
  
  .features-section .card:hover {
    transform: translateY(-5px);
  }
  