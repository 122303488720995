

.demoAudio {
  width: 300px;
  margin-right: 30px;
  place-items: end;
}

.howItWorks p span:last-child {
  margin-top: 13px;
}

.features-section {
  height: 35rem;
}

.features-section .grid {
  padding: 3rem;
}

.features-section>div:first-child,
.pricing>div:first-child,
.footer-cta>div:first-child {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.pricing {
  height: 100vh;
}

.footer-cta {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.testimonials p.text-xl {
  max-width: 700px;
}

@media only screen and (max-width: 1024px) {

  h1 {
    text-align: center;
    max-width: 650px;
  }
  .demo-section {
    width: 100%;
  }

  .text-section p {
    text-align: center;
    max-width: 650px;
  }
  .hero-content .text-section {
    align-items: center;
  }

}
@media only screen and (max-width: 707px) {
  .pricing {
    height: 75rem;
  }
}
@media only screen and (max-width: 410px) {
  .pricing.relative {
    height: 75rem;
  }
}
@media only screen and (max-width: 767px) {
  .features-section {
    height: 60rem;
  }
}
@media only screen and (max-width: 500px) {
  .features-section {
    height: 78rem;
  }
}

@media only screen and (max-width: 510px) {
  .pricing {
    height: 67rem;
  }
}