/* Navbar styles */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 15px 30px;
  }
  
  .navbar-logo h1 {
    color: white;
    margin: 0;
  }
  
  .navbar-cta {
    display: flex;
    align-items: center;
  }
  
  .navbar-cta button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .navbar-cta button:hover {
    background-color: #45a049;
  }
  
  .user-email {
    text-overflow: ellipsis;
  }

  .dropdown-content li {
    overflow: hidden;
    max-width: 200px;
  }

  @media only screen and (max-width: 450px) {
    .logo-title {
      display: none;
    }
  }